import React from 'react'
import styled, { css } from 'styled-components'

// Elements
import ParseContent from 'components/shared/ParseContent'

// Interface
import { TextProps } from 'components/flex/Text/TextShell'

const Section = styled.div<{ bgColor?: string }>`
  ${(props) =>
    props.bgColor === 'grey' &&
    css`
      background-color: ${({ theme }) => theme.color.grey};
    `}

  & p > span {
    margin-bottom: -1.75rem;
  }
`

const Content = styled(ParseContent)`
  & h1,
  & h2,
  & h3,
  & h4,
  & h5,
  & h6 {
    margin-bottom: 0.5rem;
    font-weight: 500;
  }

  & h2 {
    @media (min-width: 992px) {
      font-size: ${({ theme }) => theme.font.size.larger};
    }

    @media (max-width: 991.98px) {
      font-size: ${({ theme }) => theme.font.size.large};
    }
  }

  & blockquote {
    font-style: italic;
    padding-left: 2.5rem;

    & h3 {
      @media (min-width: 992px) {
        font-size: ${({ theme }) => theme.font.size.large};
      }

      @media (max-width: 991.98px) {
        font-size: ${({ theme }) => theme.font.size.bigger};
      }
    }
  }
`

const Text: React.FC<TextProps> = ({ fields }) => {
  return (
    <Section bgColor={fields.bgcolor}>
      <div className="container py-5">
        <div className="row justify-content-center">
          <div className="col-lg-8">
            <Content content={fields.description} />
          </div>
        </div>
      </div>
    </Section>
  )
}

export default Text
